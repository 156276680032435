<template>
    <div class="welcome">
        <SquareLogo v-if="showlogo" />
    </div>
</template>
<script>
import SquareLogo from '@/components/SquareLogo.vue';
export default {
    name: 'Welcome',
    data(){
        return {
            showlogo: false
        }
    },
    components:{
        SquareLogo,
    },
    mounted(){
        this.showlogo = true;
        setTimeout(()=>{
            this.$router.push({name: 'home'})
        }, 2000);
    },
    methods:{
    }
}
</script>
<style lang="less" scoped>
.welcome{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    // background: url("~@/assets/imgs/bg.jpg") no-repeat;
    // background-size: cover;
}


</style>
